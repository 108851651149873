* {
  box-sizing: border-box;
  margin: 0px;
}

body {
  margin: auto;
  font-family: sans-serif;
}

nav,
footer {
  height: 70px;
  display: flex;
  padding: 20px 36px;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

section {
  padding: 20px;
}

.grey {
  color: gray;
}

.bold {
  font-weight: bold;
}

.nav--logo {
  max-width: 100px;
}

.middle {
  max-width: 500px;
  margin: auto;
}

.hero--photo {
  max-width: 400px;
  align-self: center;
  padding-bottom: 20px;
}

.hero {
  display: flex;
  flex-direction: column;
}

.hero--header {
  margin-bottom: 5px;
}

.hero--text {
  margin-top: 0;
}

.cards--list {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.card {
  max-width: 175px;
  font-size: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 10px;
}

.card--img {
  width: 173px;
  height: 250px;
  object-fit: cover;
  border-radius: 9px;
  padding-bottom: 5px;
}

.card--stats {
  display: flex;
  align-items: center;
  padding: 4px;
}

.card--title {
  padding: 0px 4px;
}

.card--star {
  height: 12px;
  padding-right: 4px;
}
